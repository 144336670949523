<template>
  <div>
    <span v-if="!results">{{ noDataText }}</span>
    <v-data-table
      v-show="results"
      :headers="headers"
      :items="items"
      dense
      class="elevation-1"
    >
      <template v-slot:item.title="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <a :href="item.url" target="_blank">{{
                truncate(item.title, 20)
              }}</a>
            </span>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.sessions="{ item }">
        <span>{{ formatNumber(item.sessions) }}</span>
      </template>
      <template v-slot:item.views="{ item }">
        <span>{{ formatNumber(item.views) }}</span>
      </template>
      <template v-slot:item.ctr="{ item }">
        <span>{{ formatPercent(item.ctr) }}</span>
      </template>
      <template v-slot:item.clicks="{ item }">
        <span>{{ formatNumber(item.clicks) }}</span>
      </template>
      <template v-slot:item.impressions="{ item }">
        <span>{{ formatNumber(item.impressions) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { truncate } from "@/utils/formatting";
import { getDateStr } from "@/utils/dates";
import { formatPercent } from "@/utils/formatting";
import { formatNumber } from "@/utils/formatting";
import { noDataText } from "@/utils/constants";

export default {
  name: "SessionsTopDiscoverPostsTable",
  created() {
    this.getData();
  },
  data() {
    return {
      noDataText,
      headers: [
        { text: "Titre", value: "title" },
        { text: "Sessions", value: "sessions" },
        { text: "Pages vues", value: "views" },
        { text: "CTR", value: "ctr" },
        { text: "Clics", value: "clicks" },
        { text: "Impressions", value: "impressions" },
        { text: "PVS", value: "pvs" },
        { text: "Created", value: "created" },
        { text: "Première apparition", value: "first_seen" },
        { text: "Dernière apparition", value: "last_seen" },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    formatPercent,
    getData() {
      if (!this.results) return;
      if ("items" in this.results) {
        this.items = this.results.items;
      } else {
        this.items = [];
      }
    },
    truncate(str, n) {
      return truncate(str, n);
    },
    getDateStr,
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["sessionsInGD"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>
<style scoped></style>
