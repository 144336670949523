<template>
  <div>
    <span v-if="!results || results.items.length === 0">{{ noDataText }}</span>
    <v-data-table
      v-show="results && results.items.length > 0"
      :headers="headers"
      :items="items"
      dense
      class="elevation-1"
    >
      <template v-slot:item.title="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <a :href="item.url" target="_blank">{{
                truncate(item.title, 120)
              }}</a>
            </span>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.sessions="{ item }">
        <span>{{ formatNumber(item.sessions) }}</span>
      </template>
      <template v-slot:item.views="{ item }">
        <span>{{ formatNumber(item.views) }}</span>
      </template>
      <template v-slot:item.created="{ item }">
        <span>{{ item.created.substring(0, 10) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatNumber, truncate } from "@/utils/formatting";
import { noDataText } from "@/utils/constants";
export default {
  name: "SessionsTopDiscoverPostsTable",
  created() {
    this.getData();
  },
  data() {
    return {
      noDataText,
      headers: [
        { text: "Titre", value: "title" },
        { text: "Sessions", value: "sessions" },
        { text: "Pages vues", value: "views" },
        { text: "PVS", value: "pvs" },
        { text: "Created", value: "created" },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    truncate,
    getData() {
      if (!this.results) return;
      if ("items" in this.results) {
        this.items = this.results.items;
      } else {
        this.items = [];
      }
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["sessionsNotInGD"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>
<style scoped></style>
