var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.results)?_c('span',[_vm._v(_vm._s(_vm.noDataText))]):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.results),expression:"results"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.truncate(item.title, 20)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.sessions",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.sessions)))])]}},{key:"item.views",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.views)))])]}},{key:"item.ctr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercent(item.ctr)))])]}},{key:"item.clicks",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.clicks)))])]}},{key:"item.impressions",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.impressions)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }