<template>
  <v-expansion-panels v-model="panel" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-header> </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="ml-3">
          <v-col cols="4">
            <site-group-filter store="gd" />
          </v-col>
          <v-col cols="4">
            <site-filter store="gd" />
          </v-col>
          <v-col cols="4">
            <date-filter
              store="gd"
              :range="7"
              periodType="past-only"
              :max="maxDate"
            ></date-filter>
          </v-col>
          <v-col cols="4">
            <category-filter store="gd" />
          </v-col>
          <v-col cols="4">
            <v-select
              outlined
              v-model="periodType"
              :items="periodTypeList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Pour la période"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-spacer />
          <v-col cols="2" class="d-flex">
            <apply-filters
              title="Appliquer"
              :disabled="disabled"
              styles="height: 56px !important; width: 100% !important;"
              classList=""
            />
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="progress > 0 && progress < 100"
          :value="progress"
          color="#10396f"
          background-color="#ebebf3"
        ></v-progress-linear>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ApplyFilters,
    CategoryFilter,
    DateFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {
      disabled: true,
      loading: true,
      value: 25,
      panel: 0,
      periodTypeList: [
        { id: defaultPeriodType, name: "Articles vus" },
        { id: "articles_created", name: "Articles créés" },
      ],
    };
  },
  setup() {
    const { isLoading } = useAxios();
    const { axiosGet } = useAxios();

    return {
      isLoading,
      axiosGet,
    };
  },

  created() {
    this.updateDisabled();
  },
  methods: {
    debouncedUpdateDisabled: debounce(async function () {
      this.updateDisabled();
    }, 500),
    updateDisabled() {
      this.disabled = isEmpty(this.sites) || this.isLoading;
    },
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    },
  },
  computed: {
    sites() {
      return this.$store.getters["gd/getSites"];
    },
    categories() {
      return this.$store.getters["gd/getCategories"];
    },
    maxDate() {
      // always set the max date to 2 days ago
      // because we don't have recent google discover data
      const today = new Date();
      today.setDate(today.getDate() - 2);
      return today.toISOString().split("T")[0];
    },
    periodType: {
      get() {
        const storedValue = this.$store.getters["gd/getPeriodType"];
        const value = this.validatePeriodType(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("gd/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch("gd/updatePeriodType", this.validatePeriodType(e));
      },
    },
  },
  watch: {
    isLoading() {
      this.debouncedUpdateDisabled();
    },
    async sites() {
      this.debouncedUpdateDisabled();
    },
    categories() {
      this.debouncedUpdateDisabled();
    },
    // period() {
    //   this.debouncedUpdateDisabled();
    // },
  },
};
</script>

<style scoped></style>
