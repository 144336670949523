import { render, staticRenderFns } from "./LifetimeStatsTable.vue?vue&type=template&id=d39af71c&scoped=true&"
import script from "./LifetimeStatsTable.vue?vue&type=script&lang=js&"
export * from "./LifetimeStatsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d39af71c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VTooltip})
